import { broadcastLogin, useAuthBroadcast } from '@common'
import { LoaderLogo, RouteErrorBoundary } from '@components'
import { noop } from 'lodash-es'
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { api } from '../../api/api'
import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { persistor } from '../../app/store'
import { resetTracking, trackEvent } from '../../common/tracking'
import { ExternalTrackingScreen } from '../../pages/ExternalTrackingScreen'
import { LoginScreen } from '../../pages/LoginScreen'
import { NewODQuoteScreen } from '../../pages/NewODQuoteScreen'
import { RegisterScreen } from '../../pages/RegisterScreen'
import { getContacts } from '../../redux/quotingToolSlice'
import {
  checkIfUserIsAuthenticated,
  getCustomerCompanyList,
  getShipperProfile,
  getUser,
  logout,
  setCompanyId,
  setStep,
} from '../../redux/userSlice'
import { Header } from '../Header'
import { Sidebar } from '../Sidebar'

// lazy load pages which are not used on initial load, and contain heavy components
const CarrierDetailsScreen = lazy(() => import('../../pages/CarrierDetailsScreen'))
const CarriersScreen = lazy(() => import('../../pages/CarriersScreen'))
const ItemTemplatesScreen = lazy(() => import('../../pages/ItemTemplatesScreen'))
const ContractLanesScreen = lazy(() => import('../../pages/ContractLanesScreen'))
const ContractLaneDetailsScreen = lazy(() => import('../../pages/ContractLaneDetailsScreen'))
const LoadDetailsScreen = lazy(() => import('../../pages/LoadDetailsScreen'))
const LoadsScreen = lazy(() => import('../../pages/LoadsScreen'))
const InvoiceScreen = lazy(() => import('../../pages/InvoiceScreen'))
const FacilitiesScreen = lazy(() => import('../../pages/FacilitiesScreen'))
const FacilityDetailsScreen = lazy(() => import('../../pages/FacilityDetailsScreen'))
const PriceEstimatorScreen = lazy(() => import('../../pages/PriceEstimatorScreen'))
const ReportingScreen = lazy(() => import('../../pages/ReportingScreen'))
const QuotingToolScreen = lazy(() => import('../../pages/QuotingToolScreen'))
const QuoteDetailsScreen = lazy(() => import('../../pages/QuoteDetailsScreen'))
const NotFoundScreen = lazy(() => import('../../pages/NotFoundScreen'))
const CreateLoadScreen = lazy(() => import('../../pages/CreateLoadScreen'))
const ProjectsScreen = lazy(() => import('../../pages/ProjectsScreen'))
const CreateProjectScreen = lazy(() => import('../../pages/CreateProjectScreen'))
const ProjectDetailsScreen = lazy(() => import('../../pages/ProjectDetailsScreen'))
const ProfileDetailsScreen = lazy(() => import('../../pages/ProfileDetailsScreen'))
const RFPScreen = lazy(() => import('../../pages/RFPScreen'))
const CreateRFPScreen = lazy(() => import('../../pages/CreateRFPScreen'))
const RFPDetailsScreen = lazy(() => import('../../pages/RFPDetailsScreen'))

const PrivateRoute = () => {
  const dispatch = useAppThunkDispatch()

  useEffect(() => {
    dispatch(getUser())
    dispatch(getContacts())
    dispatch(getShipperProfile())
    dispatch(getCustomerCompanyList())
  }, [])

  return (
    <>
      <Header />
      <Sidebar />
      <Suspense>
        <Outlet />
      </Suspense>
    </>
  )
}

const SwitchContainer = () => {
  const dispatch = useAppThunkDispatch()
  const { pathname, state, search } = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const portalQuotingEnabled = useAppSelector(state => state.user.user?.portalQuotingEnabled)
  const companyId = useAppSelector(state => state.user.companyId)
  const hasPriceEstimatorFF = useAppSelector(
    state => !!state.user.user?.featureFlags?.['shipperPriceEstimator'],
  )
  const hasProjectLoadsFF = useAppSelector(
    state => !!state.user.user?.featureFlags?.['projectLoad'],
  )
  const hasRFPFF = useAppSelector(state => !!state.user.user?.featureFlags?.['rfp'])

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)

  const performLogoutActions = () => {
    setIsAuthenticated(false)
    persistor.purge()
    setTimeout(() => {
      // There's an odd bug where the user is kept in the private route
      // after logout. Here, we wait for the next event loop
      // to ensure that React has finished propagating the isAuthenticated update
      navigate('/', {
        state: { from: `${pathname}${search}` },
      })
    }, 0)
  }

  useAuthBroadcast(
    () => setIsAuthenticated(true),
    () => {
      resetTracking()
      dispatch(logout()).then(performLogoutActions)
    },
  )

  useEffect(() => {
    isAuthenticated && trackEvent(`Navigated to ${pathname}`) // Tracks each pageview.
  }, [pathname])

  const checkAuth = useCallback(() => {
    // determine if the user is authenticated and has a company selection
    // if the user is authenticated, and has selected a company, and is on the login page, redirect to home
    dispatch(checkIfUserIsAuthenticated())
      .unwrap()
      .then(async ({ authenticated, customerCompanyId }) => {
        if (!authenticated) {
          return Promise.reject()
        }

        if (customerCompanyId) {
          dispatch(setCompanyId(customerCompanyId))
          broadcastLogin()
        } else {
          dispatch(setStep('SELECT_COMPANY'))
          setIsAuthenticated(false)
        }
      })
      .catch(performLogoutActions)
  }, [])

  useEffect(() => {
    const noAuthRoutes = ['password-reset', 'register', 'tracking']
    const shouldAuth = !noAuthRoutes.some(route => pathname.includes(route))

    if (searchParams.has('token')) {
      const token = searchParams.get('token') as string

      api
        .post(`/shipper/users/contact-verification/${token}/`)
        .catch(noop)
        .finally(() => {
          checkAuth()
          searchParams.delete('token')
          setSearchParams(searchParams, { replace: true })
        })
    } else if (shouldAuth) {
      checkAuth()
    }
  }, [])

  const path = useMemo(() => {
    const defaultPath = portalQuotingEnabled ? '/quotes' : '/loads'
    return state?.from || defaultPath
  }, [portalQuotingEnabled, state])

  return (
    <Routes>
      <Route errorElement={<RouteErrorBoundary />}>
        <Route element={<RegisterScreen />} path='/register' />
        <Route element={<LoginScreen />} path='/password-reset/:token' />
        <Route element={<ExternalTrackingScreen />} path='/tracking/:trackingNumber' />
        <Route
          element={
            isAuthenticated === true && companyId ? (
              // by prodiving a composite key of companyId, we ensure the whole app re-renders when
              // they change, without having to reload the app
              // https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
              <PrivateRoute key={companyId} />
            ) : isAuthenticated === null ? (
              <LoaderLogo />
            ) : (
              <LoginScreen />
            )
          }
        >
          <Route element={<Navigate replace to={path} />} path='/' />
          <Route element={<LoadsScreen />} path='/loads' />
          <Route element={<LoadDetailsScreen />} path='/loads/:id' />
          <Route element={<CarriersScreen />} path='/carriers' />
          <Route element={<ItemTemplatesScreen />} path='/items' />
          <Route element={<ItemTemplatesScreen />} path='/items/:id' />
          <Route element={<ContractLanesScreen />} path='/contract-lanes' />
          <Route element={<ContractLaneDetailsScreen />} path='/contract-lanes/:id' />
          <Route element={<CarrierDetailsScreen />} path='/carriers/:id' />
          <Route element={<ReportingScreen />} path='/my-stats' />
          <Route element={<InvoiceScreen />} path='/invoices' />
          <Route element={<FacilitiesScreen />} path='/facilities' />
          <Route element={<FacilityDetailsScreen />} path='/facilities/:id' />
          <Route element={<ProfileDetailsScreen />} path='/account' />
          <Route element={<CreateLoadScreen />} path='/loads/new-load' />
          <Route element={<CreateLoadScreen />} path='/new-load' />
          {hasRFPFF && (
            <>
              <Route element={<RFPScreen />} path='/rfps' />
              <Route element={<CreateRFPScreen />} path='/rfps/new-rfp' />
              <Route element={<RFPDetailsScreen />} path='/rfps/:id' />
            </>
          )}
          {hasProjectLoadsFF && (
            <>
              <Route element={<ProjectsScreen />} path='/projects' />
              <Route element={<CreateProjectScreen />} path='/projects/new-project' />
              <Route element={<ProjectDetailsScreen />} path='/projects/:id' />
            </>
          )}
          {portalQuotingEnabled && (
            <>
              <Route element={<QuotingToolScreen />} path='/quotes' />
              <Route element={<QuoteDetailsScreen />} path='/quotes/:id' />
              <Route element={<NewODQuoteScreen />} path='/quotes/new-od-quote' />
            </>
          )}
          {hasPriceEstimatorFF && (
            <Route element={<PriceEstimatorScreen />} path='/price-estimator' />
          )}

          {/* catch-all 404 */}
          <Route element={<NotFoundScreen />} path='*' />
        </Route>

        {/* Redirection routes from old code */}
        <Route element={<Navigate replace state={state} to='/' />} path='login' />
      </Route>
    </Routes>
  )
}

export const SwitchRouter = (): JSX.Element => (
  <QueryParamProvider
    adapter={ReactRouter6Adapter}
    options={{
      removeDefaultsFromUrl: true,
      enableBatching: true,
    }}
  >
    <SwitchContainer />
  </QueryParamProvider>
)
