import { displayCityAndState, pluralizeNoun } from '@common'
import { Button, Counter, Link, LoadRoute, Status, Tooltip } from '@components'
import { Menu } from '@headlessui/react'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import {
  DocumentIcon,
  EllipsisVerticalIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid'
import { noop } from 'lodash-es'
import { useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppThunkDispatch } from '../../app/hooks'
import { trackEvent } from '../../common/tracking'
import { CustomerQuote, DBLoadStop, QuoteLocation } from '../../common/types'
import { displayDate, displayDateTime, formatCurrency } from '../../common/utils'
import { getLoadInvoiceURL } from '../../redux/invoiceSlice'
import { getProjects } from '../../redux/projectsSlice'
import { EaseInOutTransition } from '../EaseInOutTransition'
import { LoadStatusUpdateButton } from '../LoadDetails/components'
import { ProjectStatus } from '../ProjectDetails/components/ProjectStatus'
import { QuoteRoute } from '../QuotingTool/components'
import { TextWithTooltip } from '../TextWithTooltip'

export const FileErrors = ({ value }: { value: number[] }) => (
  <div className='flex items-center'>
    <span className='mr-4'>{value.length}</span>
    {!!value.length && (
      <Tooltip
        content={
          <TooltipContentContainer>
            <div className='mb-3'>
              We found a problem with the following {pluralizeNoun(value.length, 'row')}:
            </div>
            {value.join(', ')}
          </TooltipContentContainer>
        }
      >
        <InformationCircleIcon className='text-brand-dark w-5' />
      </Tooltip>
    )}
  </div>
)

const TooltipContentContainer = tw.div`
  w-fit
  max-w-[500px]
`

export const RouteWithStops = ({
  value,
}: {
  value: {
    shipper: string
    consignee: string
    loadStops: DBLoadStop[]
    contractLaneId: number | null
  }
}) => (
  <div>
    {value.contractLaneId && (
      <ContractLaneContainer>Contract Lane #{value.contractLaneId}</ContractLaneContainer>
    )}
    <LoadRoute
      minimized
      className='border-none pb-0 mb-0'
      destination={value.consignee}
      origin={value.shipper}
      stops={value.loadStops?.map(stop => ({
        stopType: stop.stopType === 1 ? 'Pick-up' : 'Drop-off',
        location: displayCityAndState(stop.city, stop.stateProvinceRegion),
      }))}
    />
  </div>
)

export const Price = ({ value }: { value: string | number }) =>
  value ? formatCurrency(value) : '—'

export const WithTooltip = ({ value }: { value: string }) => (
  <TextWithTooltip>{value || '—'}</TextWithTooltip>
)

export const StatusCell = ({ value }: { value: { status: string; id: number } }) => {
  const dispatch = useAppThunkDispatch()

  return (
    <ProjectStatus
      callback={() => dispatch(getProjects())}
      className='load-row-button'
      id={value.id}
      showUpdateText={false}
      status={value.status}
    />
  )
}

export const LinkCell = ({ value }: { value: { text: string; href: string } }) => (
  <div className='load-row-button whitespace-nowrap'>
    <Link to={value.href}>{value.text}</Link>
  </div>
)
export const Route = ({ value }: { value: { origin: string; destination: string } }) => {
  if (!value.origin || !value.destination) return '—'

  return (
    <div className='flex whitespace-nowrap items-center font-semibold'>
      {value.origin} <ArrowLongRightIcon className='h-4 mx-2' /> {value.destination}
    </div>
  )
}

export const QuotingRoute = ({
  value,
}: {
  value: { origin: QuoteLocation; destination: QuoteLocation; stops: QuoteLocation[] }
}) => <QuoteRoute quote={value} />

export const DateTime = ({ value }: { value: string }) => (
  <div className='whitespace-nowrap'>{displayDateTime(value)}</div>
)

export const Date = ({ value }: { value: string }) => (
  <div className='whitespace-nowrap'>{displayDate(value)}</div>
)

export const Count = ({ value }: { value: number }) => <Counter count={value} />

// to display a dash if there's no value
export const Text = ({ value }: { value: string }) => <div>{value ?? '—'}</div>

export const NoWrapText = ({ value }: { value: string }) => (
  <div className='whitespace-nowrap'>{value || '—'}</div>
)

export const AverageRates = ({ value }: { value: any }) => {
  let aveTotal = 0
  value?.forEach((quote: CustomerQuote) => (aveTotal += Number(quote.price)))
  aveTotal = aveTotal / (value?.length ?? 1)
  const aveRpm = aveTotal / (value && value.length > 0 && value[0].miles ? value[0].miles : 1)

  return (
    <div>
      <div className='whitespace-nowrap'>
        Avg RPM: <span className='font-semibold'>{formatCurrency(aveRpm || 0)}</span>
      </div>
      <div className='whitespace-nowrap'>
        Avg Total: <span className='font-semibold'>{formatCurrency(aveTotal || 0)}</span>
      </div>
    </div>
  )
}

export const FileName = ({ value }: { value: string }) => (
  <div className='flex items-center'>
    <DocumentIcon className='w-5' />
    <TextWithTooltip className='ml-2 w-[150px]' limit={25}>
      {value}
    </TextWithTooltip>
  </div>
)

export const Ellipsis = ({
  items,
  dropdownClassName,
  className,
}: {
  items: { label: string; onClick: (e: MouseEvent) => void }[]
  dropdownClassName?: string
  className?: string
}) => (
  <div className={className}>
    <MenuContainer as='div'>
      <Menu.Item>
        <MainButton data-testid='menu-button'>
          <EllipsisVerticalIcon />
        </MainButton>
      </Menu.Item>
      <EaseInOutTransition>
        <Dropdown className={dropdownClassName}>
          {items.map((item: { label: string; onClick: (e: MouseEvent) => void }) => (
            <MenuItem key={item.label} onClick={item.onClick}>
              {item.label}
            </MenuItem>
          ))}
        </Dropdown>
      </EaseInOutTransition>
    </MenuContainer>
  </div>
)

export const QuoteStatus = ({
  value,
}: {
  value: { manualQuote: { status?: string }; withTooltip?: boolean }
}) => {
  const backgrounds = {
    'OD Accepted': 'bg-claim',
    'OD Pending': 'bg-confirmed',
    Quoted: 'bg-claim',
  }

  return (
    <div className='flex items-center gap-2'>
      <Status
        status={value.manualQuote?.status || 'Quoted'}
        background={
          backgrounds[value.manualQuote?.status as keyof typeof backgrounds] || 'bg-claim'
        }
      />
      {value.withTooltip && (
        <Tooltip content='Over-Dimensional Load'>
          <InformationCircleIcon className='w-4' />
        </Tooltip>
      )}
    </div>
  )
}

export const OperationalHours = ({
  value,
}: {
  value: { openingTime: string; closingTime: string }
}) => {
  if (!value.openingTime && !value.closingTime) return <div>N/A</div>

  return (
    <div>
      {value.openingTime || 'N/A'} — {value.closingTime || 'N/A'}
    </div>
  )
}

export const QuotesSentAndReceived = ({
  value,
}: {
  value: { totalSent: number; totalReceived: number; showLabel?: boolean }
}) => (
  <div className='whitespace-nowrap'>
    <div className='max-lg:pb-1.5'>
      <span className='font-semibold'>{value.showLabel && 'Quotes'} Sent:</span> {value.totalSent}
    </div>
    <div>
      <span className='font-semibold'>{value.showLabel && 'Quotes'} Received:</span>{' '}
      {value.totalReceived}
    </div>
  </div>
)

export const QuotesPrices = ({
  value,
}: {
  value: { quoteAvg: string; quoteMax: string; quoteMin: string }
}) => (
  <div className='whitespace-nowrap max-lg:grid max-lg:gap-y-1.5'>
    {Object.values(value).some(Boolean) ? (
      <>
        <div>
          <span className='font-semibold'>Min:</span> {formatCurrency(value.quoteMin)}
        </div>
        <div>
          <span className='font-semibold'>Avg:</span> {formatCurrency(value.quoteAvg)}
        </div>
        <div>
          <span className='font-semibold'>Max:</span> {formatCurrency(value.quoteMax)}
        </div>
      </>
    ) : (
      '—'
    )}
  </div>
)

export const LoadStatus = ({ value: { getData, load } }: any) => (
  <div className='load-row-button'>
    <LoadStatusUpdateButton
      short
      getData={getData}
      load={{ ...load, newLoadStatusDisplay: load.newLoadStatus }}
      showUpdateText={false}
    />
  </div>
)

export const InvoiceActionButton = ({
  value: { network, isReceived, id, newLoadStatus, setCurrentLoadId, setModalVisible, isPaid },
}: {
  value: {
    network: number
    isReceived: boolean
    id: number
    newLoadStatus: string
    setCurrentLoadId: (value: number) => void
    setModalVisible: (value: boolean) => void
    isPaid: boolean
  }
}) => {
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppThunkDispatch()

  const viewPayInvoice = async (id: number, isReceived: boolean) => {
    setLoading(true)
    trackEvent('Clicked to view pay invoice')
    await dispatch(getLoadInvoiceURL({ id: id, isReceived: isReceived }))
      .unwrap()
      .catch(noop)
    setLoading(false)
  }

  const openModal = () => {
    setCurrentLoadId(id)
    setModalVisible(true)
  }

  return (
    <div className='load-row-button'>
      {network === 2 && (
        <Button
          innerClassName='w-[144px]'
          loading={isLoading}
          type='link'
          onClick={() => viewPayInvoice(id, isReceived)}
        >
          {isReceived ? 'View Invoice' : 'View/Pay Invoice'}
        </Button>
      )}
      {network === 4 && newLoadStatus !== 'COMPLETED' && (
        <Button innerClassName='w-[144px]' label='Pay' type='success' onClick={openModal} />
      )}
      {network === 4 && newLoadStatus === 'COMPLETED' && isPaid && (
        <Button
          innerClassName='w-[144px]'
          type='link'
          onClick={() => {
            openModal()
          }}
        >
          View Payment
        </Button>
      )}
    </div>
  )
}

export const RFPStatus = ({ value, className }: { value: string; className?: string }) => {
  const getStatusBackground = () => {
    switch (value.toLowerCase()) {
      case 'draft':
        return 'bg-white border border-brand-dark'
      case 'rates requested':
        return 'bg-exo-orange text-white'
      case 'bid evaluation':
        return 'bg-bright-blue text-white'
      case 'awarded':
        return 'bg-success text-white'
    }
  }

  return <Status background={getStatusBackground()} className={className} status={value} />
}

const MenuItem = tw(Menu.Button)`
  hover:bg-lighter-blue
  cursor-pointer
  transition-all
  whitespace-nowrap
  py-3
  px-4
  w-full
  text-left
`

const MainButton = tw(Menu.Button)`
  hover:opacity-80
  transition-all
  cursor-pointer
`

const Dropdown = tw(Menu.Items)`
  origin-top-right
  mt-2
  rounded-md
  shadow-lg
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
  bg-white
  left-auto
  bottom-auto
  absolute
  -top-8
  right-6
  py-1
`

const MenuContainer = tw(Menu)`
  relative
  inline-block
  text-left
`

const ContractLaneContainer = tw.div`
  font-semibold
  mb-1
  border
  border-brand-dark
  rounded-md
  px-2
  py-1
  w-fit
`
