import {
  CatchError,
  cleanFilters,
  formatAxiosErrorToPayload,
  formatDateFilterForBackend,
  formatDateForBackend,
  getErrorString,
  keysToCamelCase,
  keysToSnakeCase,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { countriesMapped, initialFilters } from '../common/constants'
import { SearchFilters, TableOrder } from '../common/types'
import { Lane, LaneLocation, NewRFP, RFPDocument, RFPItem } from '../common/types/rfp'
import { getOrderingString, toTitleCase, toUpperCaseUnderscore } from '../common/utils'

type BulkActionError = { id: number; error: string }

type LaneItemLocation = {
  id: number
  addressLines: null
  city: string
  state: string
  country: string
  postalCode: string
  display: string
  geoData: {
    lat: number
    lng: number
  }
}

type RFPState = {
  loading: {
    getRFPs: boolean
    createRFP: boolean
    getRFPDetails: boolean
    updateRFP: boolean
    getDocuments: boolean
    deleteDocument: boolean
    uploadDocuments: boolean
    archiveRFP: boolean
    createOrUpdateLane: boolean
    getLanes: boolean
    getLaneDetails: boolean
    archiveLane: boolean
    importLane: boolean
    downloadSampleFile: boolean
    bulkArchiveLanes: boolean
    bulkUnarchiveLanes: boolean
  }
  rfps: RFPItem[]
  rfpDetails: NewRFP
  newRfp: NewRFP
  limit: number
  offset: number
  count: number
  order: TableOrder
  lanesOrder: TableOrder
  filters: SearchFilters
  documents: RFPDocument[]
  isLaneModalVisible: boolean
  currentLaneId: string | number | null
  lanesCount: number
  lanesFilters: SearchFilters
  lanes: {
    id: number
    pickup: LaneItemLocation
    drop: LaneItemLocation
    volume: number
    mode: string
    equipmentType: string
    targetRate: string
    requestedBid: number
    receivedBid: number
    notes: string
  }[]
  laneDetails: Lane
  fileError: string
  lanesBulkActionErrors: BulkActionError[]
}

const initialState: RFPState = {
  loading: {
    getRFPs: false,
    createRFP: false,
    getRFPDetails: false,
    updateRFP: false,
    getDocuments: false,
    deleteDocument: false,
    uploadDocuments: false,
    archiveRFP: false,
    createOrUpdateLane: false,
    getLanes: false,
    getLaneDetails: false,
    archiveLane: false,
    importLane: false,
    downloadSampleFile: false,
    bulkArchiveLanes: false,
    bulkUnarchiveLanes: false,
  },
  rfps: [],
  rfpDetails: {},
  newRfp: {},
  count: 0,
  limit: 50,
  offset: 0,
  order: { direction: '', key: '', label: '' },
  lanesOrder: { direction: '', key: '', label: '' },
  filters: initialFilters,
  documents: [],
  isLaneModalVisible: false,
  currentLaneId: '',
  lanesCount: 0,
  lanesFilters: initialFilters,
  lanes: [],
  laneDetails: {},
  fileError: '',
  lanesBulkActionErrors: [],
}

const getLanePayload = (data: Lane) => {
  const setLaneLocation = (location?: LaneLocation) => ({
    city: location?.city,
    state: location?.state,
    postalCode: location?.postalCode,
    country: countriesMapped[location?.country || ''] || location?.country,
  })

  return {
    pickup: setLaneLocation(data?.origin),
    drop: setLaneLocation(data?.destination),
    equipmentType: data.equipmentType,
    volume: Number(data.volume),
    targetRate: parseFloat(data.targetRate || '0'),
    mode: data.mode,
    notes: data.notes,
    totalVolume: Number(data.totalVolume),
  }
}

export const getRFPs = createAsyncThunk('rfp/getRFPs', async (_, { getState, rejectWithValue }) => {
  const { filters } = (getState() as RootState).rfp

  try {
    const response = await api.get('/shipper/api/list-create-rfp/', {
      params: cleanFilters({
        id: filters.rfpId,
        name__icontains: filters.name,
        ...formatDateFilterForBackend(filters.startDate, 'start_date'),
        ...formatDateFilterForBackend(filters.endDate, 'end_date'),
        ...formatDateFilterForBackend(filters.carrierBidDeadline, 'carrier_bid_deadline'),
        ...formatDateFilterForBackend(filters.awardAcceptanceDeadline, 'award_acceptance_deadline'),
        fuel_setting: toUpperCaseUnderscore(filters.fuelSetting),
        distance_unit: toUpperCaseUnderscore(filters.distanceUnit),
        volume_frequency: toUpperCaseUnderscore(filters.volumeFrequency),
        bidType: toUpperCaseUnderscore(filters.bidType),
        status: toUpperCaseUnderscore(filters.rfpStatus),
        archived: filters.archived,
      }),
    })
    return keysToCamelCase(response.data)
  } catch (err: CatchError) {
    return rejectWithValue(formatAxiosErrorToPayload(err))
  }
})

export const getRFPDetails = createAsyncThunk(
  'rfp/getRFPDetails',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/rfp-rud/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createRFP = createAsyncThunk(
  'rfp/createRFP',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { newRfp } = (getState() as RootState).rfp

    const formData = new FormData()

    // @ts-ignore
    formData.append('is_public', true)
    formData.append('contact_name', newRfp.contactName ? String(newRfp.contactName) : '')
    formData.append('contact_email', newRfp.contactEmail ? String(newRfp.contactEmail) : '')
    formData.append('contact_phone', newRfp.contactPhone ? String(newRfp.contactPhone) : '')
    formData.append('carrier_bid_deadline', String(formatDateForBackend(newRfp.deadlineDate || '')))
    formData.append('bid_type', String(toUpperCaseUnderscore(newRfp.bidType)))
    formData.append('volume_frequency', String(toUpperCaseUnderscore(newRfp.volumeFrequency)))
    formData.append('start_date', String(formatDateForBackend(newRfp.startDate || '')))
    formData.append('end_date', String(formatDateForBackend(newRfp.endDate || '')))
    formData.append('distance_unit', String(toUpperCaseUnderscore(newRfp.distanceUnit)))
    formData.append('fuel_setting', String(toUpperCaseUnderscore(newRfp.fuelSettings)))
    formData.append('name', String(newRfp.name))
    formData.append('notes', String(newRfp.notes || ''))
    newRfp.files?.forEach(doc => formData.append('files', doc.file))

    try {
      const response = await api.post('/shipper/api/list-create-rfp/', formData)
      dispatch(getRFPs())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateRFP = createAsyncThunk(
  'rfp/updateRFP',
  async (data: NewRFP, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    const payload = {
      ...data,
      carrier_bid_deadline: data.deadlineDate,
      fuelSetting: toUpperCaseUnderscore(data.fuelSettings),
      bidType: toUpperCaseUnderscore(data.bidType),
      volumeFrequency: toUpperCaseUnderscore(data.volumeFrequency),
      distanceUnit: toUpperCaseUnderscore(data.distanceUnit),
    }

    try {
      const response = await api.patch(`/shipper/api/rfp-rud/${id}/`, keysToSnakeCase(payload))
      dispatch(getRFPDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const archiveRFP = createAsyncThunk(
  'rfp/archiveRFP',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.delete(`/shipper/api/rfp-rud/${id}/`)
      dispatch(getRFPDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getDocuments = createAsyncThunk(
  'rfp/getDocuments',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/list-create-rfp-document/${id}/`, {
        params: { limit: 100 },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const uploadDocuments = createAsyncThunk(
  'rfp/uploadDocuments',
  async (files: { file: File; id: string }[], { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    const formData = new FormData()

    files?.forEach(doc => {
      // @ts-ignore
      formData.append('files', doc.file)
    })
    // @ts-ignore
    formData.append('is_public', true)

    try {
      const response = await api.post(`/shipper/api/list-create-rfp-document/${id}/`, formData)
      dispatch(getDocuments(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteDocument = createAsyncThunk(
  'rfp/deleteDocument',
  async (documentId: string | number, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.delete(`/shipper/api/delete-rfp-document/${documentId}/`, {
        params: { limit: 100 },
      })
      dispatch(getDocuments(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createLane = createAsyncThunk(
  'rfp/createLane',
  async (data: Lane, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.post(
        `/shipper/api/list-create-rfp-lane/${id}/`,
        keysToSnakeCase(getLanePayload(data)),
      )
      dispatch(getLanes(id))
      dispatch(getRFPDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLanes = createAsyncThunk(
  'rfp/getLanes',
  async (id: string | number, { getState, rejectWithValue }) => {
    const {
      lanesOrder: { label, direction, key },
      lanesFilters,
    } = (getState() as RootState).rfp

    const ordering = getOrderingString(label, direction, key, '-id')

    try {
      const response = await api.get(`/shipper/api/list-create-rfp-lane/${id}/`, {
        params: cleanFilters({
          limit: 500,
          ordering,
          id: lanesFilters.id,
          pickup_city: lanesFilters.originCity,
          pickup_state: lanesFilters.originState,
          drop_city: lanesFilters.destinationCity,
          drop_state: lanesFilters.destinationState,
          equipment_type: lanesFilters.equipmentType,
          mode: lanesFilters.mode,
        }),
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLaneDetails = createAsyncThunk(
  'rfp/getLaneDetails',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/rfp-lane-rud/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const archiveLane = createAsyncThunk(
  'rfp/archiveLane',
  async (id: string | number | null, { getState, dispatch, rejectWithValue }) => {
    const { rfpDetails } = (getState() as RootState).rfp

    try {
      const response = await api.delete(`/shipper/api/rfp-lane-rud/${id}/`)
      dispatch(getLanes(rfpDetails.id || ''))
      dispatch(getRFPDetails(rfpDetails.id || ''))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateLane = createAsyncThunk(
  'rfp/updateLane',
  async (lane: Lane, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
      currentLaneId,
    } = (getState() as RootState).rfp

    try {
      const response = await api.patch(
        `/shipper/api/rfp-lane-rud/${currentLaneId}/`,
        keysToSnakeCase(getLanePayload(lane)),
      )
      dispatch(getLanes(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const importLane = createAsyncThunk(
  'rfp/importLane',
  async (file: File | null, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    const formData = new FormData()

    if (file) formData.append('file', file)

    try {
      const response = await api.post(`/shipper/api/import-rfp-lane/${id}/`, formData)
      setTimeout(() => {
        dispatch(getLanes(id))
        dispatch(getRFPDetails(id))
      }, 3000)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const downloadSampleFile = createAsyncThunk(
  'rfp/downloadSampleFile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        'https://docs.google.com/spreadsheets/d/e/2PACX-1vT3Ku5fNCmmdLB_jZ8nLrAOjcMpTtlSShis0DehihO4bs0bhW9rp_KMtN0hNxnN_XkcZvzZMqb9ZYP3/pub?gid=0&single=true&output=csv',
      )
      if (!response.ok) throw new Error('Network response was not ok')
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'sample.csv'
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bulkArchiveLanes = createAsyncThunk(
  'rfp/bulkArchiveLanes',
  async (laneIds: number[], { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.post(
        `/shipper/api/delete-bulk-rfp-lanes/${id}/`,
        keysToSnakeCase({ laneIds }),
      )
      dispatch(getLanes(id))
      dispatch(getRFPDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const rfpSlice = createSlice({
  name: 'rfp',
  initialState,
  reducers: {
    setLimit(state, { payload }) {
      state.limit = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setNewRfp(state, { payload }) {
      state.newRfp = payload
    },
    setLaneModalVisible(state, { payload }) {
      state.isLaneModalVisible = payload
    },
    setCurrentLaneId(state, { payload }) {
      state.currentLaneId = payload
    },
    setFileError(state, { payload }) {
      state.fileError = payload
    },
    setLanesOrder(state, { payload }) {
      state.lanesOrder = payload
    },
    setLanesFilters(state, { payload }) {
      state.lanesFilters = payload
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getRFPs.pending, state => {
        state.loading.getRFPs = true
      })
      .addCase(getRFPs.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.getRFPs = false
        state.count = count
        state.rfps = results
      })
      .addCase(getRFPs.rejected, (state, { payload }) => {
        state.loading.getRFPs = false
        toast.error(getErrorString(payload, 'Failed to get RFPs'))
      })
      .addCase(createRFP.pending, state => {
        state.loading.createRFP = true
      })
      .addCase(createRFP.fulfilled, state => {
        state.loading.createRFP = false
        toast.success('Successfully created new RFP')
      })
      .addCase(createRFP.rejected, (state, { payload }) => {
        state.loading.createRFP = false
        toast.error(getErrorString(payload, 'Failed to create new RFP'))
      })
      .addCase(getRFPDetails.pending, state => {
        state.loading.getRFPDetails = true
      })
      .addCase(getRFPDetails.fulfilled, (state, { payload }) => {
        state.loading.getRFPDetails = false
        state.rfpDetails = {
          ...payload,
          fuelSettings: toTitleCase(payload.fuelSetting),
          deadlineDate: payload.carrierBidDeadline,
          distanceUnit: payload.distanceUnit === 'KM' ? 'KM' : toTitleCase(payload.distanceUnit),
          volumeFrequency: toTitleCase(payload.volumeFrequency),
          bidType: toTitleCase(payload.bidType),
        }
      })
      .addCase(getRFPDetails.rejected, (state, { payload }) => {
        state.loading.getRFPDetails = false
        toast.error(getErrorString(payload, 'Failed to get RFP details'))
      })
      .addCase(updateRFP.pending, state => {
        state.loading.updateRFP = true
      })
      .addCase(updateRFP.fulfilled, state => {
        state.loading.updateRFP = false
        toast.success('Successfully updated RFP details')
      })
      .addCase(updateRFP.rejected, (state, { payload }) => {
        state.loading.updateRFP = false
        toast.error(getErrorString(payload, 'Failed to update RFP details'))
      })
      .addCase(getDocuments.pending, state => {
        state.loading.getDocuments = true
      })
      .addCase(getDocuments.fulfilled, (state, { payload }) => {
        state.loading.getDocuments = false
        state.documents = payload.results
      })
      .addCase(getDocuments.rejected, (state, { payload }) => {
        state.loading.getDocuments = false
        toast.error(getErrorString(payload, 'Failed to get RFP documents'))
      })
      .addCase(deleteDocument.pending, state => {
        state.loading.deleteDocument = true
      })
      .addCase(deleteDocument.fulfilled, state => {
        state.loading.deleteDocument = false
        toast.success('Successfully deleted document')
      })
      .addCase(deleteDocument.rejected, (state, { payload }) => {
        state.loading.deleteDocument = false
        toast.error(getErrorString(payload, 'Failed to delete document'))
      })
      .addCase(uploadDocuments.pending, state => {
        state.loading.uploadDocuments = true
      })
      .addCase(uploadDocuments.fulfilled, state => {
        state.loading.uploadDocuments = false
        toast.success('Successfully uploaded document(s)')
      })
      .addCase(uploadDocuments.rejected, (state, { payload }) => {
        state.loading.uploadDocuments = false
        toast.error(getErrorString(payload, 'Failed to upload document(s)'))
      })
      .addCase(archiveRFP.pending, state => {
        state.loading.archiveRFP = true
      })
      .addCase(archiveRFP.fulfilled, state => {
        state.loading.archiveRFP = false
        toast.success('Successfully archived RFP')
      })
      .addCase(archiveRFP.rejected, (state, { payload }) => {
        state.loading.archiveRFP = false
        toast.error(getErrorString(payload, 'Failed to archive RFP'))
      })
      .addCase(createLane.pending, state => {
        state.loading.createOrUpdateLane = true
      })
      .addCase(createLane.fulfilled, state => {
        state.loading.createOrUpdateLane = false
        toast.success('Successfully created new lane')
      })
      .addCase(createLane.rejected, (state, { payload }) => {
        state.loading.createOrUpdateLane = false
        toast.error(getErrorString(payload, 'Failed to create new lane'))
      })
      .addCase(getLanes.pending, state => {
        state.loading.getLanes = true
      })
      .addCase(getLanes.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.getLanes = false
        state.lanesCount = count
        state.lanes = results
      })
      .addCase(getLanes.rejected, (state, { payload }) => {
        state.loading.getLanes = false
        toast.error(getErrorString(payload, 'Failed to get lanes'))
      })
      .addCase(getLaneDetails.pending, state => {
        state.loading.getLaneDetails = true
      })
      .addCase(getLaneDetails.fulfilled, (state, { payload }) => {
        state.loading.getLaneDetails = false

        const setLocation = (location: any) => ({
          city: location.city,
          state: location.state,
          country: location.country,
          latitude: location.geoData.lat,
          longitude: location.geoData.lng,
          title: location.display,
          name: location.display,
          postalCode: location.postalCode,
        })

        state.laneDetails = {
          origin: setLocation(payload.pickup),
          destination: setLocation(payload.drop),
          equipmentType: payload.equipmentType,
          mode: payload.mode,
          volume: String(payload.volume),
          targetRate:
            payload.targetRate === '0.00'
              ? ''
              : payload.targetRate.endsWith('.00')
                ? String(parseInt(payload.targetRate, 10))
                : String(payload.targetRate),
          notes: payload.notes || '',
          totalVolume: payload.totalVolume,
        }
      })
      .addCase(getLaneDetails.rejected, (state, { payload }) => {
        state.loading.getLaneDetails = false
        toast.error(getErrorString(payload, 'Failed to get lane details'))
      })
      .addCase(archiveLane.pending, state => {
        state.loading.archiveLane = true
      })
      .addCase(archiveLane.fulfilled, state => {
        state.loading.archiveLane = false
        toast.success('Successfully archived lane')
      })
      .addCase(archiveLane.rejected, (state, { payload }) => {
        state.loading.archiveLane = false
        toast.error(getErrorString(payload, 'Failed to archive lane'))
      })
      .addCase(updateLane.pending, state => {
        state.loading.createOrUpdateLane = true
      })
      .addCase(updateLane.fulfilled, state => {
        state.loading.createOrUpdateLane = false
        toast.success('Successfully updated lane')
      })
      .addCase(updateLane.rejected, (state, { payload }) => {
        state.loading.createOrUpdateLane = false
        toast.error(getErrorString(payload, 'Failed to update lane'))
      })
      .addCase(importLane.pending, state => {
        state.loading.importLane = true
        state.fileError = ''
      })
      .addCase(importLane.fulfilled, (state, { payload }) => {
        state.loading.importLane = false
        toast.success(payload || 'Successfully imported lane')
      })
      .addCase(importLane.rejected, (state, { payload }) => {
        state.loading.importLane = false
        state.fileError = String((payload as any).message)
        toast.error('Failed to process file')
      })
      .addCase(downloadSampleFile.pending, state => {
        state.loading.downloadSampleFile = true
        toast.success('Successfully downloaded sample file')
      })
      .addCase(downloadSampleFile.fulfilled, state => {
        state.loading.downloadSampleFile = false
        toast.success('Successfully downloaded sample file')
      })
      .addCase(downloadSampleFile.rejected, state => {
        state.loading.downloadSampleFile = false
        toast.error('Failed to download sample file')
      })
  },
})

export const {
  setLimit,
  setOffset,
  setOrder,
  setFilters,
  setNewRfp,
  reset,
  setLaneModalVisible,
  setCurrentLaneId,
  setFileError,
  setLanesOrder,
  setLanesFilters,
} = rfpSlice.actions

export default rfpSlice.reducer
