import { Button, Checkbox, Loader, OrderButton } from '@components'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useExpanded, useTable } from 'react-table'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../app/hooks'
import { initialFilters } from '../../common/constants'
import { SearchFilters } from '../../common/types'
import { getTableOrderDisplay } from '../../common/utils'
import { EmptyTableMessage } from './EmptyTableMessage'
import { PaginationContainer } from './PaginationContainer'
import { Filters, FiltersHeader } from './Search'

type BulkActionItem = {
  name: string
  onClick: () => void
}

type BulkActionsProps = {
  selectedItems: number[]
  setSelectedItems: (input: number | number[]) => void
  actions: BulkActionItem[]
}

type TableProps = {
  columns: any
  data: Array<any>
  setSize: (size: number) => void
  loading: boolean
  emptyText: any
  count: number
  setOffset: (offset: number) => void
  offset: number
  size: number
  filters?: any
  setFilters?: (value: SearchFilters) => void
  setOrder?: (order: any) => void
  orderBy?: any
  renderRowSubComponent?: any
  tableClassName?: string
  loaderClassName?: string
  isPaginated?: boolean
  selectedRowId?: number | null
  noLoader?: boolean
  filtersList?: readonly (keyof SearchFilters)[]
  mobileComponent?: any
  clickable?: boolean
  onRowClick?: (value: any) => void
  requestData?: () => void
  renderTags?: ((row: any) => ReactNode) | null
  tdClassName?: string
  showPagination?: boolean
  showPresets?: boolean
  bulkActions?: BulkActionsProps
}

export const Table = ({
  columns,
  data,
  setSize,
  loading,
  emptyText,
  count,
  setOffset,
  offset,
  size,
  filters = initialFilters,
  setFilters = () => {},
  setOrder = () => {},
  orderBy = {},
  renderRowSubComponent,
  tableClassName = '',
  loaderClassName = '',
  isPaginated = true,
  selectedRowId,
  noLoader,
  filtersList = [],
  mobileComponent = null,
  clickable,
  onRowClick,
  requestData,
  renderTags = null,
  tdClassName,
  showPagination = true,
  showPresets = true,
  bulkActions,
}: TableProps) => {
  const showFilters = useAppSelector(state => state.user.showFilters)

  const navigate = useNavigate()
  const location = useLocation()

  // @ts-ignore
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      autoResetExpanded: true,
    },
    useExpanded,
  )

  const [active, setActive] = useState((offset + size) / size)

  const allItemsSelected = useMemo(
    () => !!data.length && bulkActions?.selectedItems.length === data.length,
    [bulkActions?.selectedItems, data.length],
  )
  const selectAllItems = () =>
    bulkActions?.setSelectedItems(allItemsSelected ? [] : data.map(item => item.id))

  const headers = headerGroups
    .map((headerGroup: any) =>
      headerGroup.headers.map((header: any) => ({
        label: header.Header,
        key: header.orderKey,
      })),
    )
    .flat()

  // getting sortable headers
  const orderingHeaders = headers.filter((header: any) => header.key)

  const renderTableHeader = () => (
    <thead className='z-[3] relative'>
      {headerGroups.map((headerGroup, i: number) => (
        <tr {...headerGroup.getHeaderGroupProps()} key={i}>
          {headerGroup.headers.map((column: any, i: number) => {
            const isSortable = orderingHeaders
              .map((header: any) => header.label)
              .includes(column.render('Header'))

            const isSorted = column.render('Header') === orderBy.label

            return (
              <TH
                key={i}
                $isSortable={isSortable}
                className={column.className || ''}
                style={{ width: column.totalWidth }}
                onClick={() =>
                  column.orderKey &&
                  setOrder(getTableOrderDisplay(orderingHeaders, column.render('Header'), orderBy))
                }
                {...column.getHeaderProps()}
              >
                <div className='flex items-center'>
                  <span className='truncate text-ellipsis overflow-hidden font-semibold'>
                    {column.render('Header')}
                  </span>
                  {isSortable && <OrderButton isSorted={isSorted} order={orderBy} />}
                </div>
              </TH>
            )
          })}
        </tr>
      ))}
    </thead>
  )

  const renderTableBody = () => (
    <tbody {...getTableBodyProps()}>
      {rows.map((row: any, i: number) => {
        prepareRow(row)

        const isEven = i % 2 === 0

        const colorMap: { [key: string]: string } = {
          danger: 'bg-danger hover:bg-danger',
          warn: 'bg-warn hover:bg-warn',
          blue: 'bg-light-blue hover:bg-light-blue',
        }

        const bg = row.original.metaDetail?.color ? colorMap[row.original.metaDetail.color] : ''

        return (
          <React.Fragment key={i}>
            {renderTags && <tr>{renderTags(row)}</tr>}
            <TR
              $clickable={!!clickable || !!onRowClick}
              $id={row.original.id || row.id}
              $isEven={isEven}
              $selectedRowId={selectedRowId}
              className={bg}
              onClick={({ target }: { target: HTMLElement }) => {
                // prevent executing row click callback if a button was pressed
                if (target.closest('.load-row-button')) return
                onRowClick && onRowClick(row.original)
              }}
              {...row.getRowProps()}
            >
              {row.cells.map((cell: any, i: number) => {
                const href =
                  cell.column.type !== 'action'
                    ? `${location.pathname}/${cell.row.original.id}`
                    : ''
                return (
                  <TD
                    key={i}
                    $clickable={clickable}
                    className={cell.column.tdClassName || tdClassName}
                    onClick={event => {
                      if (cell.column.id == 'checkbox') event.stopPropagation()
                    }}
                    {...cell.getCellProps()}
                  >
                    {clickable ? (
                      <LinkContainer
                        $withTags={!!renderTags}
                        className={cell.column.className}
                        to={href}
                      >
                        {cell.render('Cell')}
                      </LinkContainer>
                    ) : (
                      cell.render('Cell')
                    )}
                  </TD>
                )
              })}
            </TR>
            {row.isExpanded && (
              <tr>
                <td colSpan={columns.length}>
                  {renderRowSubComponent({ row, isEven, showFilters })}
                </td>
              </tr>
            )}
          </React.Fragment>
        )
      })}
    </tbody>
  )

  const hasFilters = filtersList.length

  const Pagination = useCallback(
    () => (
      <PaginationContainer
        active={active}
        count={count}
        offset={offset}
        pageSize={size}
        setActive={setActive}
        setOffset={setOffset}
        setSize={setSize}
      />
    ),
    // @ts-ignore
    [active, count, offset, size],
  )

  const renderDesktopView = () => {
    const heightOffset = (hasFilters ? 48 : 0) + (bulkActions ? 44 : 0)
    return (
      <DesktopOuterContainer
        $hasFilters={Boolean(hasFilters)}
        $showFilters={showFilters}
        style={{ height: `calc(100% - ${heightOffset}px)` }}
      >
        <DesktopInnerContainer className={tableClassName}>
          <div className='block relative h-full overflow-auto'>
            <table {...getTableProps()} className='w-full border-separate border-spacing-0'>
              {renderTableHeader()}
              {loading && !noLoader && <TableLoader className={loaderClassName} />}
              {rows.length ? renderTableBody() : !loading && <EmptyTable emptyText={emptyText} />}
            </table>
          </div>
        </DesktopInnerContainer>
        {isPaginated && showPagination && <Pagination />}
      </DesktopOuterContainer>
    )
  }

  const renderMobileView = () => {
    const mobileHeaders = headers.map((header: any) => header.label)

    const renderCustomItems = () =>
      data.map((item: any) => (
        <Item key={item.id} className='p-0'>
          {mobileComponent(item)}
        </Item>
      ))

    const renderItems = () => (
      <div>
        {rows.map((row: any, i: number) => {
          prepareRow(row)

          const hasWideValues = row.cells.some((cell: any) => cell.column.width >= 300)

          return (
            <Item key={i} className='block' {...row.getRowProps()}>
              <MobileComponent $hasWideValues={hasWideValues}>
                {row.cells.map((cell: any, i: number) => (
                  <div key={i} {...cell.getCellProps()}>
                    <div className='text-dark-gray mb-1'>
                      {cell.column.mobileHeader || mobileHeaders[i]}
                    </div>
                    {cell.render('Cell')}
                  </div>
                ))}
              </MobileComponent>
              {clickable && (
                <Button
                  fullWidth
                  className='mt-4'
                  innerClassName='w-full'
                  type='link'
                  onClick={() => navigate(`${location.pathname}/${row.original.id}`)}
                >
                  Details
                </Button>
              )}
            </Item>
          )
        })}
      </div>
    )

    return (
      <div className='relative lg:hidden'>
        {loading && !noLoader && <Loader className='lg:hidden -top-px' />}
        <MobileTable $hasFilters={hasFilters}>
          <EmptyTableMessage
            length={count}
            loading={loading}
            text={(emptyText || '').replace('No', '')}
          />
          <div className='mb-36'>{mobileComponent ? renderCustomItems() : renderItems()}</div>
          {isPaginated && !!count && (
            <MobilePagination>
              <Pagination />
            </MobilePagination>
          )}
        </MobileTable>
      </div>
    )
  }

  return (
    <TableContainer>
      {!!hasFilters && (
        <FiltersHeader filters={filters} setFilters={setFilters} showPresets={showPresets} />
      )}
      {bulkActions && (
        <div className='flex items-center px-4 py-3'>
          <Checkbox
            className='font-semibold w-[100px]'
            indeterminate={bulkActions.selectedItems.length > 0 && !allItemsSelected}
            isChecked={allItemsSelected}
            wrapperClassName='w-5 h-5'
            title={
              bulkActions.selectedItems.length === 0
                ? 'Select All'
                : `${bulkActions.selectedItems.length} selected`
            }
            onChange={selectAllItems}
          />

          {bulkActions.actions.map((action, index) => (
            <BulkAction
              key={index}
              selectedLength={bulkActions?.selectedItems.length || 0}
              onClick={() => {
                if (bulkActions?.selectedItems.length > 0) {
                  action.onClick()
                }
              }}
            >
              {action.name}
            </BulkAction>
          ))}
        </div>
      )}

      <div className='lg:flex lg:h-full'>
        {!!hasFilters && (
          <Filters
            filters={filters}
            filtersList={filtersList}
            requestData={requestData}
            setFilters={setFilters}
          />
        )}
        {renderDesktopView()}
      </div>
      {renderMobileView()}
    </TableContainer>
  )
}

const TableLoader = ({ className }: { className?: string }) => (
  <tbody className='z-10 absolute w-full'>
    <tr>
      <td>
        <Loader className={className} />
      </td>
    </tr>
  </tbody>
)

const MobileComponent = tw.div<{ $hasWideValues: boolean }>`
  grid
  gap-4
  ${({ $hasWideValues }) => ($hasWideValues ? 'grid-cols-1' : 'grid-cols-2')}
`

const MobileTable = tw.div<{ $hasFilters: number }>`
  flex
  flex-col
  pt-4
  overflow-y-auto
  overflow-x-hidden
  ${({ $hasFilters }) => ($hasFilters ? 'h-[calc(100vh-240px)]' : 'h-[calc(100vh-201px)]')}
`

const MobilePagination = tw.div`
  fixed
  bottom-0
  pt-2
  bg-white
  flex
  lg:hidden
  items-center
  justify-center
  border-t
  border-border-gray
  w-full
`

const TD = tw.td<{ $clickable: boolean }>`
  ${({ $clickable }) => !$clickable && 'px-5 py-3'}
`

const LinkContainer = tw(Link)<{ $withTags: boolean }>`
  flex
  px-4
  py-3
  z-0
  cursor-pointer
  ${({ $withTags }) => $withTags && 'pt-8'}
`

const Item = tw.div`
  border
  border-border-gray
  rounded-lg
  mx-4
  mb-4
  max-w-screen
  p-4
  grid
  gap-4
`

const TH = tw.th`
  font-normal
  p-4
  text-left
  overflow-ellipsis
  border-b
  border-gray-200
  sticky
  top-0
  bg-white
  z-[1]
  ${({ $isSortable }: { $isSortable: boolean }) => $isSortable && 'cursor-pointer'}
`

const TR = tw.tr<{ $isEven: boolean; $id: number; $selectedRowId: number; $clickable: boolean }>`
  hover:bg-lighter-blue
  transition-all
  ${({ $isEven }) => ($isEven ? 'bg-white' : 'bg-lighter-gray')}
  ${({ $id, $selectedRowId }) => $id === $selectedRowId && 'bg-light-blue'}
  ${({ $clickable }) => $clickable && 'cursor-pointer'}
`

const EmptyTable = ({ emptyText }: { emptyText: string }) => (
  <tbody>
    <tr>
      <td className='w-full absolute text-center mt-4 text-dark-gray'>No {emptyText} found</td>
    </tr>
  </tbody>
)

const DesktopOuterContainer = tw.div<{ $hasFilters: boolean; $showFilters: boolean }>`
  hidden
  lg:!flex
  flex-col
  h-full
  transition-all
  ${({ $showFilters, $hasFilters }) =>
    $showFilters && $hasFilters ? 'w-[calc(100%-270px)]' : 'w-full'}
`

const DesktopInnerContainer = tw.div`
  text-xs
  relative
  flex-1
  overflow-hidden
`

const TableContainer = tw.div`
  relative
  flex-1
  overflow-hidden
  w-full
  h-full
`

const BulkAction = tw.span<{ selectedLength: number }>`
  flex
  mr-6
  ml-4
  font-semibold
  ${({ selectedLength }) =>
    selectedLength === 0 ? 'text-disabled-gray' : 'text-link cursor-pointer'}
`
