import {
  Button,
  Checkbox,
  LoaderSpinner,
  MobileActionButtons,
  OrderButton,
  TextInput,
} from '@components'
import { orderBy } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { EligibleCarrier } from '../../../../common/types'
import { bulkRequestQuotes } from '../../../../redux/loadsSlice'
import { Carrier } from './Carrier'
import { EnterQuoteModal } from './EnterQuoteModal'
import { RetenderWarningModal } from './RetenderWarningModal'
import { TenderLoadModal } from './TenderLoadModal'

const isSelectable = (carrier: EligibleCarrier) => carrier.contact?.email && !carrier.quote

export const CarriersList = () => {
  const [query, setQuery] = useState('')
  const [selectedCarrierIds, setSelectedCarrierIds] = useState<number[]>([])
  const [order, setOrder] = useState({ direction: 'ascending', key: '', label: '' })

  const dispatch = useAppThunkDispatch()

  const loading = useAppSelector(state => state.loads.loading.loadDetails)
  const bulkRequestLoading = useAppSelector(state => state.loads.loading.bulkRequestQuotes)
  const load = useAppSelector(state => state.loads.loadDetails)

  const carriers = useMemo(
    () =>
      orderBy(
        load?.eligibleCarriers || [],
        [
          ({ name }) => name === load?.carrier?.name,
          ({ quote }) => quote?.quoteStatus === 1,
          ({ quote }) => (quote?.quoteStatus === 1 ? Number(quote.carrierBid) : Infinity),
          ({ quote }) => quote?.quoteStatus === 7,
        ],
        [
          'desc',
          'desc',
          order.direction === 'ascending' || !order.direction ? 'asc' : 'desc',
          'desc',
        ],
      ),
    [order, load],
  )

  const filteredCarriers = useMemo(
    () => carriers.filter(carrier => carrier.name?.toLowerCase().includes(query.toLowerCase())),
    [carriers, query],
  )

  const selectAll = () =>
    setSelectedCarrierIds(
      selectedCarrierIds.length === filteredCarriers.filter(isSelectable).length
        ? []
        : filteredCarriers
            .filter(isSelectable)
            .map(c => c.carrierId)
            .filter(id => id > 0),
    )

  const selectCarrier = (id: number) =>
    setSelectedCarrierIds(
      selectedCarrierIds.includes(id)
        ? selectedCarrierIds.filter(c => c !== id)
        : [...selectedCarrierIds, id],
    )

  const bulkRequest = () =>
    dispatch(bulkRequestQuotes(selectedCarrierIds))
      .unwrap()
      .then(() => setSelectedCarrierIds([]))

  const handleSort = () =>
    setOrder({
      ...order,
      direction:
        order.direction === '' ? 'ascending' : order.direction === 'ascending' ? 'descending' : '',
    })

  const CheckboxInput = useCallback(
    () => (
      <Checkbox
        primary
        disabled={!filteredCarriers.filter(isSelectable).length}
        indeterminate={!!selectedCarrierIds.length}
        title='Select All'
        isChecked={
          !!selectedCarrierIds.length &&
          selectedCarrierIds.length === filteredCarriers.filter(isSelectable).length
        }
        onChange={selectAll}
      />
    ),
    [filteredCarriers, selectedCarrierIds],
  )

  return (
    <div className='mt-6 max-lg:overflow-hidden lg:pr-5'>
      <div className='max-lg:px-4'>
        <TextInput
          sm
          className='max-lg:mb-4'
          icon='search'
          placeholder='Search'
          value={query}
          onChange={setQuery}
        />
        <div className='lg:hidden flex justify-end mb-4'>
          <CheckboxInput />
        </div>
        <div className='flex w-full mt-3 max-lg:hidden'>
          <TH className='w-[28%]'>Carrier Company</TH>
          <TH className='w-[30%]'>Contact</TH>
          <TH className='w-[16%]'>Email Status</TH>
          <TH className='w-[22%] flex items-center'>
            <CheckboxInput />
            <div className='cursor-pointer' onClick={handleSort}>
              <OrderButton isSorted={!!order.direction} order={order} />
            </div>
          </TH>
          <TH className='w-[4%]' />
        </div>
        {!filteredCarriers.length && !loading && (
          <div className='my-4 text-center text-dark-gray'>No carriers found</div>
        )}
        {loading && <LoaderSpinner />}
      </div>
      <div className='h-[calc(100vh-380px)] lg:h-[calc(100vh-353px)] overflow-auto relative w-full'>
        <div className='max-lg:px-4'>
          {filteredCarriers.map(carrier => (
            <Carrier
              key={carrier.carrierId}
              carrier={carrier}
              isSelected={selectedCarrierIds.includes(carrier.carrierId)}
              selectCarrier={selectCarrier}
            />
          ))}
        </div>
        {!!filteredCarriers.length && (
          <RequestButtonContainer>
            <Button
              disabled={!selectedCarrierIds.length}
              loading={bulkRequestLoading}
              type='link'
              onClick={bulkRequest}
            >
              Request quotes from selected carriers
            </Button>
          </RequestButtonContainer>
        )}
      </div>
      <EnterQuoteModal />
      <TenderLoadModal />
      <RetenderWarningModal />
      <MobileActionButtons
        addable
        addNewText='Request quotes from selected carriers'
        isAddButtonDisabled={!selectedCarrierIds.length}
        isAddButtonLoading={bulkRequestLoading}
        onAdd={bulkRequest}
      />
    </div>
  )
}

const TH = tw.div`
  font-semibold
  py-4
  border-b
  border-light-gray
  whitespace-nowrap
`

const RequestButtonContainer = tw.div`
  sticky
  bottom-2
  bg-lighter-blue
  w-[calc(100%-16px)]
  flex
  justify-end
  py-3
  px-6
  rounded-lg
  mt-4
  max-lg:hidden
`
