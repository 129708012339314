import {
  CarrierLocationTrackingEvent,
  CatchError,
  formatAxiosErrorToPayload,
  getErrorString,
  randomString,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { sortBy } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { emptyLocation, initialFilters } from '../common/constants'
import {
  CarrierQuote,
  DBLoadStop,
  EligibleCarrier,
  LoadDocument,
  LoadItem,
  LoadListItem,
  LoadNotification,
  NewLoadLocation,
  SearchFilters,
  TableOrder,
} from '../common/types'
import {
  formatDateForBackend,
  formatLoadStop,
  getOrderingString,
  keysToCamelCase,
  keysToSnakeCase,
  mapLoadStatus,
  normalizeLoadPayload,
} from '../common/utils'
import { NewLoadDimensions } from './createNewLoadSlice'

type TenderedCarrier = {
  id: number
  carrier: {
    id: number
    name: string
    mcNumber: string
    dotNumber: string
  }
  statusDisplay: string
  carrierContractRate: string
  sentAt: string
  expiresAt: string
  status: string
  order: number
}

type BulkActionError = { loadId: number; error: string }

type LoadsState = {
  loading: {
    loads: boolean
    loadDetails: boolean
    documents: boolean
    uploadDocument: boolean
    deleteDocument: boolean
    updateLoad: boolean
    quotes: boolean
    exoDetails: boolean
    createBOL: boolean
    createCarrierQuote: boolean
    deleteLoadStop: boolean
    addLoadStop: boolean
    locationEvents: boolean
    addNewManifestItem: boolean
    updateManifestItem: boolean
    deleteManifestItem: boolean
    bulkRequestQuotes: boolean
    updateStatus: boolean
    notifications: boolean
    tenderLoad: boolean
    cancelTender: boolean
    updateLoadStop: boolean
    triggerWaterfall: boolean
    getWaterfall: boolean
    getProjectLoads: boolean
    bulkArchiveLoads: boolean
    bulkUnarchiveLoads: boolean
  }
  count: {
    loads: number
    documents: number
    projectLoads: number
  }
  loads: LoadListItem[]
  selectedLoads: number[]
  bulkActionErrors: BulkActionError[]
  offset: number
  projectLoadsOffset: number
  limit: number
  projectLoadsLimit: number
  order: TableOrder
  projectLoadsOrder: TableOrder
  filters: SearchFilters
  projectLoadsFilters: SearchFilters
  loadDetails: any
  documents: LoadDocument[]
  quotes: Array<any>
  exoDetails: any
  carrierQuotes: CarrierQuote[]
  isCarrierSidebarVisible: boolean
  carrierProps: {
    id: number | null
    carrierPrice: string
  }
  origin: NewLoadLocation
  destination: NewLoadLocation
  loadStops: NewLoadLocation[]
  currentStep: number
  completedSteps: number[]
  equipmentType: string
  specialRequirements: number[]
  unNumber: string
  highValueAmount: string
  refId: string
  dimensions: NewLoadDimensions
  mode: number | null
  isEnterQuoteModalVisible: boolean
  isTenderLoadModalVisible: boolean
  isRequestQuoteModalVisible: boolean
  isCancelTenderModalVisible: boolean
  isRetenderModalVisible: boolean
  isTenderDisabled: boolean
  locationTrackingEvents: CarrierLocationTrackingEvent[]
  notifications: LoadNotification[]
  loadItems: LoadItem[]
  eligibleCarriers: EligibleCarrier[]
  loadPrice: string
  currentCarrier: EligibleCarrier | null
  newLoadStatus: { isUpdating: boolean; newStatusDisplay: string; newStatus: string }
  selectedLoadId: number
  tenderedCarriers: TenderedCarrier[]
  loadRoute: {
    locationId: number
    locationName: string
    stopType: 'Pickup' | 'Drop'
    locationType: 'Origin' | 'Destination' | 'Stop'
  }[]
  projectLoads: LoadListItem[]
  projectLoadsInitialCount: number
}

const initialState: LoadsState = {
  loading: {
    loads: false,
    loadDetails: false,
    documents: false,
    uploadDocument: false,
    deleteDocument: false,
    updateLoad: false,
    quotes: false,
    exoDetails: false,
    createBOL: false,
    createCarrierQuote: false,
    deleteLoadStop: false,
    addLoadStop: false,
    locationEvents: false,
    addNewManifestItem: false,
    deleteManifestItem: false,
    updateManifestItem: false,
    bulkRequestQuotes: false,
    updateStatus: false,
    notifications: false,
    tenderLoad: false,
    cancelTender: false,
    updateLoadStop: false,
    triggerWaterfall: false,
    getWaterfall: false,
    getProjectLoads: false,
    bulkArchiveLoads: false,
    bulkUnarchiveLoads: false,
  },
  count: {
    loads: 0,
    documents: 0,
    projectLoads: 0,
  },
  loads: [],
  selectedLoads: [],
  bulkActionErrors: [],
  offset: 0,
  projectLoadsOffset: 0,
  limit: 50,
  projectLoadsLimit: 300,
  order: { label: '', direction: '', key: '' },
  projectLoadsOrder: { label: '', direction: '', key: '' },
  filters: initialFilters,
  projectLoadsFilters: initialFilters,
  loadDetails: null,
  documents: [],
  quotes: [],
  exoDetails: {},
  carrierQuotes: [],
  isCarrierSidebarVisible: false,
  carrierProps: { id: null, carrierPrice: '' },
  origin: emptyLocation,
  destination: emptyLocation,
  loadStops: [],
  currentStep: 0,
  completedSteps: [],
  equipmentType: '',
  specialRequirements: [],
  unNumber: '',
  highValueAmount: '',
  refId: '',
  dimensions: { width: '', height: '', length: '', weight: '', temperature: '', description: '' },
  mode: null,
  isEnterQuoteModalVisible: false,
  isTenderLoadModalVisible: false,
  isRequestQuoteModalVisible: false,
  isCancelTenderModalVisible: false,
  isRetenderModalVisible: false,
  isTenderDisabled: false,
  locationTrackingEvents: [],
  notifications: [],
  loadItems: [],
  eligibleCarriers: [],
  loadPrice: '',
  currentCarrier: null,
  newLoadStatus: { isUpdating: false, newStatus: '', newStatusDisplay: '' },
  selectedLoadId: -1,
  tenderedCarriers: [],
  loadRoute: [],
  projectLoads: [],
  projectLoadsInitialCount: 0,
}

const getLocationErrors = (location: Partial<NewLoadLocation>) => {
  const errors = []
  if (!location.location?.address) errors.push('Address is incomplete')
  if (!location.date) errors.push('Missing date')
  return errors
}

const getLoadsFilters = (filters: SearchFilters) => ({
  archived: !!filters.archivedLoads,
  id: filters.loadId,
  pickup_date__gte: formatDateForBackend(filters.pickupStartDate || ''),
  pickup_date__lte: formatDateForBackend(filters.pickupEndDate || ''),
  delivery_date__gte: formatDateForBackend(filters.deliveryStartDate || ''),
  delivery_date__lte: formatDateForBackend(filters.deliveryEndDate || ''),
  ...(filters.loadStatuses?.length && {
    new_load_status__in: filters.loadStatuses.join(',').replaceAll('+', '_'),
  }),
  created_by: filters.user || null,
  shipper__city__icontains: filters.originCity || null,
  shipper__state_province_region__in: filters.originState || null,
  consignee__city__icontains: filters.destinationCity || null,
  consignee__state_province_region__in: filters.destinationState || null,
  project_name: filters.projectName || null,
})

export const getProjectLoadsCount = createAsyncThunk(
  'loads/getProjectLoadsCount',
  async (projectId: string | number, { getState, rejectWithValue }) => {
    const { projectLoadsLimit: limit, projectLoadsOffset: offset } = (getState() as RootState).loads

    try {
      const response = await api.get('/customer/api/customer-loads/', {
        params: {
          limit,
          offset,
          project_id: projectId,
          archived: false,
        },
      })
      return keysToCamelCase(response.data.count)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getProjectLoads = createAsyncThunk(
  'loads/getProjectLoads',
  async (projectId: string | number, { getState, rejectWithValue }) => {
    const {
      projectLoadsLimit: limit,
      projectLoadsOffset: offset,
      projectLoadsFilters: filters,
    } = (getState() as RootState).loads

    try {
      const response = await api.get('/customer/api/customer-loads/', {
        params: {
          limit,
          offset,
          project_id: projectId,
          ...getLoadsFilters(filters),
          archived: false,
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoads = createAsyncThunk(
  'loads/getLoads',
  async (_, { getState, rejectWithValue }) => {
    const {
      limit,
      offset,
      filters,
      order: { label, direction, key },
    } = (getState() as RootState).loads

    const ordering = getOrderingString(label, direction, key, '-id')

    try {
      const response = await api.get('/customer/api/customer-loads/', {
        params: {
          limit,
          offset,
          ordering,
          project_id: filters.projectId || null,
          ...getLoadsFilters(filters),
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadDetail = createAsyncThunk(
  'loads/getLoadDetail',
  async (loadId: string | undefined, { rejectWithValue }) => {
    try {
      const response = await api.get(`/customer/api/customer-load-detail/${loadId}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const archiveLoad = createAsyncThunk(
  'loads/archiveLoad',
  async (loadId: string, { dispatch, rejectWithValue }) => {
    try {
      await api.delete(`/customer/api/customer-load-detail/${loadId}/`)
      dispatch(getLoadDetail(loadId))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getDocuments = createAsyncThunk(
  'loads/getDocuments',
  async ({ id, limit = 10 }: { id?: string | number; limit?: number }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/customer/api/load-upload-list-create/${id}/`, {
        params: { limit },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const uploadDocument = createAsyncThunk(
  'loads/uploadDocument',
  async (
    { file, showToCarriers, type }: { file: File | null; showToCarriers?: boolean; type?: number },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { loadDetails } = (getState() as RootState).loads

    const flData = new FormData()
    if (file) flData.append('file', file)
    flData.append('document_type', type === 5 ? '5' : showToCarriers ? '13' : '3')

    try {
      await api.post(`/customer/api/load-upload-list-create/${loadDetails.id}/`, flData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      dispatch(getDocuments({ id: loadDetails.id }))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteDocument = createAsyncThunk(
  'loads/deleteDocument',
  async (
    { id, loadId, limit }: { id: number; loadId?: string; limit?: number },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { loadDetails } = (getState() as RootState).loads
    try {
      await api.delete(`/customer/api/load-upload-rd/${id}/`)
      dispatch(getDocuments({ id: loadId || loadDetails.id, limit: limit || 100 }))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateLoad = createAsyncThunk(
  'loads/updateLoad',
  async (
    { id, patchPayload }: { id?: string; patchPayload?: any },
    { getState, dispatch, rejectWithValue },
  ) => {
    const {
      origin,
      destination,
      loadStops,
      equipmentType,
      specialRequirements,
      unNumber,
      highValueAmount,
      refId,
      loadDetails,
      dimensions,
      loadPrice,
      mode,
    } = (getState() as RootState).loads

    const payload = {
      ...normalizeLoadPayload(
        origin,
        destination,
        loadStops,
        equipmentType,
        specialRequirements,
        unNumber,
        highValueAmount,
        refId,
        false,
        [],
        dimensions,
        mode,
      ),
      ...(loadDetails.network === 2 ? { customerPrice: loadPrice } : { carrierPrice: loadPrice }),
    }

    try {
      await api.patch(
        `/customer/api/customer-load-detail/${id || loadDetails.id}/`,
        keysToSnakeCase(patchPayload || payload),
      )
      dispatch(getLoadDetail(id || loadDetails.id))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bulkArchiveLoads = createAsyncThunk(
  'loads/bulkArchiveLoads',
  async (selectedLoads: number[], { rejectWithValue }) => {
    try {
      const response = await api.post('/customer/api/bulk-archive-loads/', { ids: selectedLoads })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bulkUnarchiveLoads = createAsyncThunk(
  'loads/bulkUnarchiveLoads',
  async (selectedLoads: number[], { rejectWithValue }) => {
    try {
      const response = await api.post('/customer/api/bulk-unarchive-loads/', { ids: selectedLoads })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addLoadStop = createAsyncThunk(
  'loads/addLoadStop',
  async (newStop: NewLoadLocation, { getState, dispatch, rejectWithValue }) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      const response = await api.post(
        `/shipper/api/loads/${loadDetails.id}/stop/`,
        keysToSnakeCase(formatLoadStop(newStop)),
      )
      dispatch(getLoadDetail(loadDetails.id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteLoadStop = createAsyncThunk(
  'loads/deleteLoadStop',
  async (id: string | number, { getState, dispatch, rejectWithValue }) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      await api.delete(`/shipper/api/loads/stop/${id}/`)
      dispatch(getLoadDetail(loadDetails.id))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateLoadStop = createAsyncThunk(
  'loads/updateLoadStop',
  async (stop: NewLoadLocation, { getState, dispatch, rejectWithValue }) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      await api.patch(`/shipper/api/loads/stop/${stop.id}/`, keysToSnakeCase(formatLoadStop(stop)))
      dispatch(getLoadDetail(loadDetails.id))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createBOL = createAsyncThunk(
  'loads/createBOL',
  async ({ loadId }: { loadId: string }, { dispatch, rejectWithValue }) => {
    try {
      await api.post(`/loads/api/create-bol/${loadId}/`)
      dispatch(getDocuments({ id: loadId }))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createCarrierQuote = createAsyncThunk(
  'loads/createCarrierQuote',
  async (
    {
      carrierBid,
      carrierId,
      carrierContact,
    }: {
      carrierBid?: string
      carrierId: number
      carrierContact?: { name?: string; phone?: string; email?: string } | null
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { loadDetails } = (getState() as RootState).loads

    const payload = {
      carrierBid: carrierBid ? parseFloat(carrierBid) : null,
      carrierId,
      ...(carrierContact && { carrierContact }),
    }

    try {
      await api.post(
        `/customer/api/loads/${loadDetails.id}/carrier-quotes/`,
        keysToSnakeCase(payload),
      )
      dispatch(getLoadDetail(loadDetails.id))
      return carrierBid ? 'entered' : 'requested'
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bulkRequestQuotes = createAsyncThunk(
  'loads/bulkRequestQuotes',
  async (ids: number[], { getState, dispatch, rejectWithValue }) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      await api.post(
        `/customer/api/loads/${loadDetails.id}/bulk-carrier-quotes/`,
        keysToSnakeCase({
          requestQuotes: ids.map(carrierId => ({ carrierId })),
        }),
      )
      dispatch(getLoadDetail(loadDetails.id))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLocationTrackingEvents = createAsyncThunk(
  'loads/getLocationTrackingEvents',
  async (loadId?: number) => {
    const url = loadId
      ? `/tracking/location-tracking-event/${loadId}/`
      : '/tracking/location-tracking-event/'
    const response = await api.get(url)
    return response.data.map((event: any) => {
      const [longitude, latitude] = event.geo_point.split('(')[1].split(')')[0].split(' ')
      return {
        timestamp: new Date(event.timestamp).toISOString(),
        accuracy: event.accuracy,
        coordinates: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        },
      }
    })
  },
)

export const updateStatus = createAsyncThunk(
  'loads/updateStatus',
  async (
    data: {
      loadId: number
      status: string
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.post(
        `/loads/api/load-status-update/${data.loadId}/`,
        keysToSnakeCase(data),
      )
      dispatch(getLoadDetail(String(data.loadId)))
      dispatch(getNotifications(data.loadId))
      return keysToCamelCase(response.data) as {
        newLoadStatus: string
        eventTime: string
      }
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getNotifications = createAsyncThunk(
  'loads/getNotifications',
  async (loadId: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/load-notifications/${loadId}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addNewManifestItem = createAsyncThunk(
  'loads/addNewManifestItem',
  async (
    payload: {
      name?: string
      quantity?: number
      pickupStopRef?: string
      drops?: { stopRef: string; quantity: number }[]
      itemTemplateId?: string | number
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      const response = await api.post(
        `/loads/api/loads/${loadDetails.id}/items/`,
        keysToSnakeCase(payload),
      )
      dispatch(getLoadItems(loadDetails.id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateManifestItem = createAsyncThunk(
  'loads/updateManifestItem',
  async (
    payload: {
      name?: string
      quantity?: number
      pickupStopRef?: string
      drops?: { stopRef: string; quantity: number }[]
      itemTemplateId?: string | number
      itemMappingKey?: string
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      const response = await api.patch(
        `/loads/api/loads/items/${payload.itemMappingKey}`,
        keysToSnakeCase(payload),
      )
      dispatch(getLoadItems(loadDetails.id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteManifestItem = createAsyncThunk(
  'loads/deleteManifestItem',
  async (itemMappingKey: string, { getState, dispatch, rejectWithValue }) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      const response = await api.delete(`/loads/api/loads/items/${itemMappingKey}/`)
      dispatch(getLoadItems(loadDetails.id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const tenderLoad = createAsyncThunk(
  'loads/tenderLoad',
  async (
    { carrierId, amount }: { carrierId?: number; amount: string },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      const response = await api.post(
        `/shipper/api/loads/${loadDetails.id}/tender/`,
        keysToSnakeCase({ carrierId, amount, tenderRequest: 'START' }),
      )
      dispatch(getLoadDetail(loadDetails.id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const cancelTender = createAsyncThunk(
  'loads/cancelTender',
  async ({ carrierId }: { carrierId?: number }, { getState, dispatch, rejectWithValue }) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      const response = await api.post(
        `/shipper/api/loads/${loadDetails.id}/tender/`,
        keysToSnakeCase({ carrierId, amount: null, tenderRequest: 'CANCEL' }),
      )
      dispatch(getLoadDetail(loadDetails.id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadItems = createAsyncThunk(
  'loads/getLoadItems',
  async (id: string | number) =>
    await api.get(`/loads/api/loads/${id}/items/`).then(({ data }) => keysToCamelCase(data)),
)

export const triggerWaterfall = createAsyncThunk(
  'loads/triggerWaterfall',
  async (trigger: 'START' | 'CANCEL', { getState, dispatch, rejectWithValue }) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      const response = await api.post(`/loads/api/waterfall-carrier-tender/${loadDetails.id}/`, {
        trigger,
        is_shipper: true,
      })
      dispatch(getWaterfall(loadDetails.id))
      dispatch(getLoadDetail(loadDetails.id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getWaterfall = createAsyncThunk(
  'loads/getWaterfall',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/loads/api/waterfall-carrier-tender/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadRoute = createAsyncThunk(
  'loads/getLoadRoute',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/loads/api/load-routes/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const loadsSlice = createSlice({
  name: 'loads',
  initialState,
  reducers: {
    setSelectedLoads(state, { payload }) {
      state.selectedLoads =
        typeof payload === 'number'
          ? state.selectedLoads.includes(payload)
            ? state.selectedLoads.filter(field => field !== payload)
            : [...state.selectedLoads, payload]
          : payload
    },
    setLimit(state, { payload }) {
      state.limit = payload
    },
    setProjectLoadsLimit(state, { payload }) {
      state.projectLoadsLimit = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setProjectLoadsOffset(state, { payload }) {
      state.projectLoadsOffset = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setProjectLoadsOrder(state, { payload }) {
      state.projectLoadsOrder = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setProjectLoadsFilters(state, { payload }) {
      state.projectLoadsFilters = payload
    },
    // I noticed that it breaks something and moved it back, will try extracting it again later
    formatLoadData(state, { payload }) {
      const getLocationDisplay = (location?: {
        name?: string
        address?: string
        city?: string
        stateProvinceRegion?: string
        postalCode?: string
      }) =>
        location
          ? `${location?.name ? `${location?.name} | ` : ''}${
              location?.address ? `${location?.address}, ` : ''
            }${location?.city} ${location?.postalCode}, ${location?.stateProvinceRegion}`
          : ''

      const { shipper, consignee } = payload
      state.equipmentType = payload.equipmentType
      state.refId = payload.customerReferenceId
      const requirements = []
      if (payload.isHazmat) {
        requirements.push(0)
        state.unNumber = payload.hazmatUhn
      }
      if (payload.isHighValue) {
        requirements.push(1)
        state.highValueAmount = payload.highValueAmount
      }
      if (payload.isTeamLoad) requirements.push(2)
      if (payload.isOversize) requirements.push(3)
      state.specialRequirements = requirements
      state.origin = {
        location: { ...shipper, display: getLocationDisplay(shipper) },
        contactPhone: shipper?.contactPhone,
        contactName: shipper?.contactName,
        date: payload.pickupDate,
        earlyTime: payload.pickupEarlyTime,
        lateTime: payload.pickupLateTime,
        isValid: false,
        items: [],
        shipmentNumber: payload.shipperPickupNumber,
        notes: payload.shipperPickupNotes,
      }
      state.destination = {
        location: { ...consignee, display: getLocationDisplay(consignee) },
        contactPhone: consignee?.contactPhone,
        contactName: consignee?.contactName,
        date: payload.deliveryDate,
        earlyTime: payload.deliveryEarlyTime,
        lateTime: payload.deliveryLateTime,
        isValid: false,
        items: [],
        shipmentNumber: payload.consigneeDeliveryNumber,
        notes: payload.consigneeDeliveryNotes,
      }
      state.loadStops = payload.loadstopSet.map((stop: Partial<DBLoadStop>) => ({
        id: stop.id,
        type: stop.stopType === 1 ? 'Pick-up' : 'Drop-off',
        location: {
          ...stop,
          name: stop.locationName,
          display: getLocationDisplay({ ...stop, name: stop.locationName }),
        },
        contactPhone: stop.phone,
        contactName: stop.contactName,
        date: stop.stopDate,
        earlyTime: stop.stopEarlyTime,
        lateTime: stop.stopLateTime,
        isValid: false,
        items: [],
        shipmentNumber: stop.shipmentNumber,
        notes: stop.notes,
        order: stop.order,
      }))
      state.dimensions = {
        weight: payload.loadWeight,
        temperature: payload.temperature,
        width: payload.loadWidth,
        height: payload.loadHeight,
        length: payload.loadLength,
        description: payload.description,
      }
      if (payload.loadItems) {
        payload.loadItems.forEach((item: any) => {
          if (item.pickupStopRef === 'ORIGIN') {
            state.origin = {
              ...state.origin,
              items: [
                ...state.origin.items,
                {
                  ...item,
                  createdAt: dayjs().format('YYYY-MM-DD'),
                  dropoffs: item.drops.map((drop: any) => {
                    const dropStop = state?.loadStops.find(
                      stop => stop.order === Number(drop.stopRef) - 1,
                    )
                    const stopLabel = `Stop | ${dropStop?.location.name}`

                    return {
                      quantity: drop.quantity,
                      label: drop.stopRef === 'DESTINATION' ? 'Destination' : stopLabel,
                      value:
                        drop.stopRef === 'DESTINATION' ? 'Destination' : (dropStop?.order || 0) + 1,
                      id: randomString(),
                      isValid: true,
                    }
                  }),
                },
              ],
            }
          }

          state.loadStops = state.loadStops.map(stop =>
            stop.order === Number(item.pickupStopRef) - 1
              ? {
                  ...stop,
                  items: [
                    ...stop.items,
                    {
                      ...item,
                      createdAt: dayjs().format('YYYY-MM-DD'),
                      dropoffs: item.drops.map((drop: any) => ({
                        quantity: drop.quantity,
                        label:
                          drop.stopRef === 'DESTINATION'
                            ? 'Destination'
                            : `Stop | ${stop.location.name}`,
                        value:
                          drop.stopRef === 'DESTINATION' ? 'Destination' : (stop.order || 0) + 1,
                        id: randomString(),
                        isValid: true,
                      })),
                    },
                  ],
                }
              : stop,
          )

          item.drops.forEach((drop: any) => {
            const dropItem = {
              id: drop.id,
              from: item.pickupStopRef,
              quantity: drop.quantity,
              name: item.name,
              item_mapping_key: drop.item_mapping_key,
            }

            if (drop.stopRef === 'DESTINATION') {
              state.destination = {
                ...state.destination,
                items: [...state.destination.items, dropItem],
              }
            } else {
              state.loadStops = state.loadStops.map(stop =>
                stop.order === Number(drop.stopRef) - 1
                  ? {
                      ...stop,
                      items: [...(stop.items || []), dropItem],
                    }
                  : stop,
              )
            }
          })
        })
      }
      state.origin = { ...state.origin, error: getLocationErrors(state.origin) }
      state.destination = { ...state.destination, error: getLocationErrors(state.destination) }
      state.loadStops = state.loadStops.map(stop => ({
        ...stop,
        error: getLocationErrors(stop),
      }))
      state.isTenderDisabled =
        !!getLocationErrors(state.origin).length ||
        !!getLocationErrors(state.destination).length ||
        state.loadStops.some(stop => getLocationErrors(stop).length) ||
        !payload.equipmentType
      state.eligibleCarriers = payload.eligibleCarriers
      state.loadPrice = payload.network === 2 ? payload.customerPrice : payload.carrierPrice
    },
    setOrigin(state, { payload }) {
      state.origin = payload
    },
    setDestination(state, { payload }) {
      state.destination = payload
    },
    setLoadStops(state, { payload }) {
      state.loadStops = payload
    },
    setEquipmentType(state, { payload }) {
      state.equipmentType = payload
    },
    setSpecialRequirements(state, { payload }) {
      state.specialRequirements = state.specialRequirements.includes(payload)
        ? state.specialRequirements.filter(req => req !== payload)
        : [...state.specialRequirements, payload]
    },
    setUNNumber(state, { payload }) {
      state.unNumber = payload
    },
    setHighValueAmount(state, { payload }) {
      state.highValueAmount = payload
    },
    setRefId(state, { payload }) {
      state.refId = payload
    },
    setLoadPrice(state, { payload }) {
      state.loadPrice = payload
    },
    setMode(state, { payload }) {
      state.mode = payload
    },
    setDimensions(state, { payload }) {
      state.dimensions = payload
    },
    setEnterQuoteModalVisible(state, { payload }) {
      state.isEnterQuoteModalVisible = payload
    },
    setTenderLoadModalVisible(state, { payload }) {
      state.isTenderLoadModalVisible = payload
    },
    setRequestQuoteModalVisible(state, { payload }) {
      state.isRequestQuoteModalVisible = payload
    },
    setCancelTenderModalVisible(state, { payload }) {
      state.isCancelTenderModalVisible = payload
    },
    setRetenderModalVisible(state, { payload }) {
      state.isRetenderModalVisible = payload
    },
    setCurrentCarrier(state, { payload }) {
      state.currentCarrier = payload
    },
    setNewLoadStatus(state, { payload }) {
      state.newLoadStatus = payload
    },
    setSelectedLoadId(state, { payload }) {
      state.selectedLoadId = payload
    },
    setBulkActionErrors(state, { payload }) {
      state.bulkActionErrors = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLoads.pending, state => {
        state.loading.loads = true
      })
      .addCase(getLoads.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.loads = false
        state.count.loads = count
        state.loads = results.map((load: any) => ({
          ...load,
          route: [
            load.shipper || '—',
            ...load.loadStops.map(
              (stop: any) => `${stop.city}, ${stop.state || stop.stateProvinceRegion}`,
            ),
            load.consignee || '—',
          ],
          newLoadStatus: mapLoadStatus(load.newLoadStatus),
        }))
        state.selectedLoads = []
      })
      .addCase(getLoads.rejected, (state, { payload }) => {
        state.loading.loads = false
        toast.error(getErrorString(payload, 'Error getting loads'))
      })
      .addCase(getLoadDetail.pending, state => {
        state.loading.loadDetails = true
      })
      .addCase(getLoadDetail.fulfilled, (state, { payload }) => {
        state.loading.loadDetails = false
        state.loadDetails = {
          ...payload,
          isCreatedWithPdf: payload.createMethod === 9,
        }
        state.mode = payload.mode
        state.destination = {
          ...state.destination,
          error: getLocationErrors(state.destination),
        }
        state.loadStops = payload.loadstopSet.map((stop: Partial<DBLoadStop>) => ({
          ...stop,
          error: getLocationErrors(stop),
        }))
        state.origin = { ...state.origin, error: getLocationErrors(state.origin) }
        state.isTenderDisabled =
          !!getLocationErrors(state.origin).length ||
          !!getLocationErrors(state.destination).length ||
          state.loadStops.some(stop => getLocationErrors(stop).length) ||
          payload.equipmentType === 'Unknown'
      })
      .addCase(getLoadDetail.rejected, (state, { payload }) => {
        state.loading.loadDetails = false
        toast.error(getErrorString(payload, 'Error getting load details'))
      })
      .addCase(archiveLoad.pending, state => {
        state.loading.loadDetails = true
      })
      .addCase(archiveLoad.fulfilled, state => {
        state.loading.loadDetails = false
        toast.success('Load successfully archived')
      })
      .addCase(archiveLoad.rejected, (state, { payload }) => {
        state.loading.loadDetails = false
        toast.error(getErrorString(payload, 'Error archiving load'))
      })
      .addCase(getDocuments.pending, state => {
        state.loading.documents = true
      })
      .addCase(getDocuments.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.documents = false
        state.count.documents = count
        state.documents = results
      })
      .addCase(getDocuments.rejected, (state, { payload }) => {
        state.loading.documents = false
        toast.error(getErrorString(payload, 'Error getting load documents'))
      })
      .addCase(uploadDocument.pending, state => {
        state.loading.uploadDocument = true
      })
      .addCase(uploadDocument.fulfilled, state => {
        state.loading.uploadDocument = false
        toast.success('Successfully uploaded document')
      })
      .addCase(uploadDocument.rejected, (state, { payload }) => {
        state.loading.uploadDocument = false
        toast.error(getErrorString(payload, 'Error uploading document'))
      })
      .addCase(deleteDocument.pending, state => {
        state.loading.deleteDocument = true
      })
      .addCase(deleteDocument.fulfilled, state => {
        state.loading.deleteDocument = false
        toast.success('Successfully deleted document')
      })
      .addCase(deleteDocument.rejected, (state, { payload }) => {
        state.loading.deleteDocument = false
        toast.error(getErrorString(payload, 'Error deleting document'))
      })
      .addCase(updateLoad.pending, state => {
        state.loading.updateLoad = true
      })
      .addCase(updateLoad.fulfilled, state => {
        state.loading.updateLoad = false
        toast.success('Successfully updated load')
      })
      .addCase(updateLoad.rejected, (state, { payload }) => {
        state.loading.updateLoad = false
        toast.error(getErrorString(payload, 'Failed to update load'))
      })
      .addCase(bulkArchiveLoads.pending, state => {
        state.loading.bulkArchiveLoads = true
      })
      .addCase(bulkArchiveLoads.fulfilled, (state, { payload }) => {
        state.loading.bulkArchiveLoads = false
        const { errors } = payload
        state.bulkActionErrors = errors
        if (errors.length === 0) toast.success('Successfully archived loads')
        else toast.error('Some loads could not be archived')
      })
      .addCase(bulkArchiveLoads.rejected, (state, { payload }) => {
        state.loading.bulkArchiveLoads = false
        toast.error(getErrorString(payload, 'Failed to archive loads'))
      })
      .addCase(bulkUnarchiveLoads.pending, state => {
        state.loading.bulkUnarchiveLoads = true
      })
      .addCase(bulkUnarchiveLoads.fulfilled, (state, { payload }) => {
        state.loading.bulkUnarchiveLoads = false
        const { errors } = payload
        state.bulkActionErrors = errors
        if (errors.length === 0) toast.success('Successfully unarchived loads')
        else toast.error('Some loads could not be unarchived')
      })
      .addCase(bulkUnarchiveLoads.rejected, (state, { payload }) => {
        state.loading.bulkUnarchiveLoads = false
        toast.error(getErrorString(payload, 'Failed to unarchived'))
      })
      .addCase(createBOL.pending, state => {
        state.loading.createBOL = true
      })
      .addCase(createBOL.fulfilled, state => {
        state.loading.createBOL = false
        toast.success('Successfully created BOL')
      })
      .addCase(createBOL.rejected, (state, { payload }) => {
        state.loading.createBOL = false
        toast.error(getErrorString(payload, 'Error creating BOL'))
      })
      .addCase(createCarrierQuote.pending, state => {
        state.loading.createCarrierQuote = true
      })
      .addCase(createCarrierQuote.fulfilled, (state, { payload }) => {
        state.loading.createCarrierQuote = false
        toast.success(`Successfully ${payload} carrier quote`)
      })
      .addCase(createCarrierQuote.rejected, (state, { payload }) => {
        state.loading.createCarrierQuote = false
        toast.error(getErrorString(payload, 'Failed to create carrier quote'))
      })
      .addCase(deleteLoadStop.pending, state => {
        state.loading.deleteLoadStop = true
      })
      .addCase(deleteLoadStop.fulfilled, state => {
        state.loading.deleteLoadStop = false
        toast.success('Successfully deleted load stop')
      })
      .addCase(deleteLoadStop.rejected, (state, { payload }) => {
        state.loading.deleteLoadStop = false
        toast.error(getErrorString(payload, 'Failed to delete load stop'))
      })
      .addCase(addLoadStop.pending, state => {
        state.loading.addLoadStop = true
      })
      .addCase(addLoadStop.fulfilled, state => {
        state.loading.addLoadStop = false
        toast.success('Successfully added new stop')
      })
      .addCase(addLoadStop.rejected, (state, { payload }) => {
        state.loading.addLoadStop = false
        toast.error(getErrorString(payload, 'Failed to add new stop'))
      })
      .addCase(getLocationTrackingEvents.pending, state => {
        state.loading.locationEvents = true
      })
      .addCase(getLocationTrackingEvents.fulfilled, (state, { payload }) => {
        state.locationTrackingEvents = payload
        state.loading.locationEvents = false
      })
      .addCase(getLocationTrackingEvents.rejected, state => {
        state.loading.locationEvents = false
      })
      .addCase(getLoadItems.fulfilled, (state, { payload }) => {
        const { results } = payload
        state.loadItems = results
      })
      .addCase(getLoadItems.rejected, () => {
        toast.error('Failed to get load items')
      })
      .addCase(addNewManifestItem.pending, state => {
        state.loading.addNewManifestItem = true
      })
      .addCase(addNewManifestItem.fulfilled, state => {
        state.loading.addNewManifestItem = false
        toast.success('Successfully added item')
      })
      .addCase(addNewManifestItem.rejected, (state, { payload }) => {
        state.loading.addNewManifestItem = false
        toast.error(getErrorString(payload, 'Failed to add manifest item'))
      })
      .addCase(deleteManifestItem.pending, state => {
        state.loading.deleteManifestItem = true
      })
      .addCase(deleteManifestItem.fulfilled, state => {
        state.loading.deleteManifestItem = false
        toast.success('Successfully deleted item')
      })
      .addCase(deleteManifestItem.rejected, (state, { payload }) => {
        state.loading.deleteManifestItem = false
        toast.error(getErrorString(payload, 'Failed to delete manifest item'))
      })
      .addCase(updateManifestItem.pending, state => {
        state.loading.updateManifestItem = true
      })
      .addCase(updateManifestItem.fulfilled, state => {
        state.loading.updateManifestItem = false
        toast.success('Successfully updated item')
      })
      .addCase(updateManifestItem.rejected, (state, { payload }) => {
        state.loading.updateManifestItem = false
        toast.error(getErrorString(payload, 'Failed to update manifest item'))
      })
      .addCase(bulkRequestQuotes.pending, state => {
        state.loading.bulkRequestQuotes = true
      })
      .addCase(bulkRequestQuotes.fulfilled, state => {
        state.loading.bulkRequestQuotes = false
        toast.success('Successfully requested quotes')
      })
      .addCase(bulkRequestQuotes.rejected, (state, { payload }) => {
        state.loading.bulkRequestQuotes = false
        toast.error(getErrorString(payload, 'Failed to request quotes'))
      })
      .addCase(updateStatus.pending, state => {
        state.loading.updateStatus = true
      })
      .addCase(updateStatus.fulfilled, (state, { payload }) => {
        state.loading.updateStatus = false
        toast.success(`Status updated to ${payload.newLoadStatus}`)
      })
      .addCase(updateStatus.rejected, (state, { payload }) => {
        state.loading.updateStatus = false
        toast.error(getErrorString(payload, 'Failed to update load status'))
      })
      .addCase(getNotifications.pending, state => {
        state.loading.notifications = true
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.notifications = payload
        state.loading.notifications = false
      })
      .addCase(getNotifications.rejected, state => {
        state.loading.notifications = false
      })
      .addCase(tenderLoad.pending, state => {
        state.loading.tenderLoad = true
      })
      .addCase(tenderLoad.fulfilled, (state, { payload }) => {
        state.loading.tenderLoad = false
        toast.success(payload || 'Successfully tendered load')
      })
      .addCase(tenderLoad.rejected, (state, { payload }) => {
        state.loading.tenderLoad = false
        toast.error(getErrorString(payload, 'Failed to tender load'))
      })
      .addCase(cancelTender.pending, state => {
        state.loading.cancelTender = true
      })
      .addCase(cancelTender.fulfilled, state => {
        state.loading.cancelTender = false
        toast.success('Successfully canceled tender')
      })
      .addCase(cancelTender.rejected, (state, { payload }) => {
        state.loading.cancelTender = false
        toast.error(getErrorString(payload, 'Failed to cancel tender'))
      })
      .addCase(updateLoadStop.pending, state => {
        state.loading.updateLoadStop = true
      })
      .addCase(updateLoadStop.fulfilled, state => {
        state.loading.updateLoadStop = false
        toast.success('Successfully updated stop')
      })
      .addCase(updateLoadStop.rejected, (state, { payload }) => {
        state.loading.updateLoadStop = false
        toast.error(getErrorString(payload, 'Failed to update stop'))
      })
      .addCase(triggerWaterfall.pending, state => {
        state.loading.triggerWaterfall = true
      })
      .addCase(triggerWaterfall.fulfilled, (state, { payload }) => {
        state.loading.triggerWaterfall = false
        toast.success(payload || 'Successfully triggered waterfall')
      })
      .addCase(triggerWaterfall.rejected, (state, { payload }) => {
        state.loading.triggerWaterfall = false
        toast.error(getErrorString(payload, 'Failed to trigger waterfall'))
      })
      .addCase(getWaterfall.pending, state => {
        state.loading.getWaterfall = true
      })
      .addCase(getWaterfall.fulfilled, (state, { payload }) => {
        state.loading.getWaterfall = false
        state.tenderedCarriers = payload
      })
      .addCase(getWaterfall.rejected, (state, { payload }) => {
        state.loading.getWaterfall = false
        toast.error(getErrorString(payload, 'Failed to get waterfall details'))
      })
      .addCase(getLoadRoute.fulfilled, (state, { payload }) => {
        state.loadRoute = payload
      })
      .addCase(getLoadRoute.rejected, (state, { payload }) => {
        toast.error(getErrorString(payload, 'Failed to get load route'))
      })
      .addCase(getProjectLoads.pending, state => {
        state.loading.getProjectLoads = true
      })
      .addCase(getProjectLoads.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.getProjectLoads = false
        const loads = results.map((load: any) => ({
          ...load,
          route: [
            load.shipper || '—',
            ...load.loadStops.map(
              (stop: any) => `${stop.city}, ${stop.state || stop.stateProvinceRegion}`,
            ),
            load.consignee || '—',
          ],
          newLoadStatus: mapLoadStatus(load.newLoadStatus),
        }))
        state.projectLoads = sortBy(loads, 'projectLoadOrder')
        state.count.projectLoads = count
      })
      .addCase(getProjectLoads.rejected, (state, { payload }) => {
        state.loading.getProjectLoads = false
        toast.error(getErrorString(payload, 'Failed to get loads'))
      })
      .addCase(getProjectLoadsCount.fulfilled, (state, { payload }) => {
        state.projectLoadsInitialCount = payload
      })
  },
})

export const {
  setLimit,
  setOffset,
  setOrder,
  setFilters,
  formatLoadData,
  setLoadStops,
  setEquipmentType,
  setSpecialRequirements,
  setRefId,
  setUNNumber,
  setHighValueAmount,
  setOrigin,
  setDestination,
  setDimensions,
  setMode,
  setEnterQuoteModalVisible,
  setTenderLoadModalVisible,
  setLoadPrice,
  setCancelTenderModalVisible,
  setRetenderModalVisible,
  setCurrentCarrier,
  setNewLoadStatus,
  setSelectedLoadId,
  setProjectLoadsOffset,
  setProjectLoadsLimit,
  setProjectLoadsOrder,
  setProjectLoadsFilters,
  setSelectedLoads,
  setBulkActionErrors,
} = loadsSlice.actions

export default loadsSlice.reducer
